import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import RelatedStories from "../../components/RelatedStories";

import iconQuote from "../../img/icon-quote.png";
import person from "../../img/jason-stone-hex.png";

const hero =
    "https://cms2.climacell.co/wp-content/uploads/2019/02/case-jetblue-hero.jpg";
const logo =
    "https://cms2.climacell.co/wp-content/uploads/2019/02/patriots-logo-color.png";

export default props => (
    <Layout>
      <SEO
          title="Gillette Stadium and the Patriots ケーススタディ | tomorrow.io"
          image={hero}
          pathname={props.location.pathname}
      />
      <section className="c-section-hero">
        <div className="row mx-0">
          <div
              className="col-lg-3 px-0"
              style={{
                minHeight: 600,
                background: `url(${hero}) no-repeat center / cover`,
              }}
          />
          <div className="col-lg-7 px-0 d-flex align-items-center">
            <div className="c-shifted-mob w-100 pb-4">
              <div className="d-flex align-items-center mb-5 pt-2 pt-lg-0">
                <img className="mr-3 mb-1" width="100" src={logo} alt="" />
                <span>ケーススタディ</span>
              </div>
              <h1 className="h-2 mb-4">MicroWeatherに取り組む</h1>
              <p className="fs-lg">
                ニューイングランド・ペイトリオッツやジレット・スタジアムの現場スタッフ、スタジアム運営者、スタジアムパーキング、そして地上管理者らは、tomorrow.ioを利用してスタジアム特有のMicroWeatherのインサイトを入手し、ウェブや携帯電話で通知します。
              </p>
            </div>
          </div>
        </div>
        <div className="text-sideways d-none d-md-block">
          42°21'02.1" N 71°03'27.6" W
        </div>
      </section>

      <section className="c-section bg-navy text-white c-section-skew">
        <div className="container">
          <div className="d-md-flex position-relative">
            <h2 className="h-3 text-nowrap mr-lg-6 mb-3">チャレンジ</h2>
            <p className="fs-lg">
              季節に関係なく、屋外エンターテイメントの会場は、気象予報を考慮した意思決定が必須です。米国マサチューセッツ州フォックスボロにあるジレット・スタジアムのオペレーションチームは、一歩先を行き、190万スクエア・フィートの広大なスタジアムにおいて、オペレーションを最適化するために、高性能なMicroWeatherデータを活用します。チームは、NFLゲームやMLSゲーム、NCAAイベント、モトクロス、モンスタートラック、コンサートといった6万5千人規模の様々な屋外イベントの開催が成功するように備えます。ジレット・スタジアムの各部署はリアルタイムの気象予報を毎日、そしてイベント開催中にモニターします。
            </p>
          </div>
        </div>
      </section>

      <section className="c-section py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <img className="my-4" src={iconQuote} alt="" />
              <div className="fs-lg mb-4 lh-lg">
                もし雷が土地に落ちる可能性がある場合、皆を避難場所へ誘導するためにも、その危険性を事前に把握する必要があります。tomorrow.ioのツールはいつそのような状況が発生するのかと、過ぎ去るのかを教えてくれます。
              </div>
              <p className="fs-lg fw-800 mb-4">
                ジェイソン・ストーン氏、Senior Director of Operations、Kraft Sports and Entertainment
              </p>
              <img className="mb-4" src={person} width="100" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="c-section">
        <div className="container">
          <div className="row">
            <h2 className="col-md-3 h-3 mb-4">tomorrow.ioのソリューション</h2>
            <div className="col-md-9 fs-lg">
              <p className="fw-800">フィールド管理</p>
              <p>
                水の飽和レベルはフィールド状態を最適化するために重要です。フィールドスタッフは、ベストなタイミングでフィールドを覆ったり、フィールドのスマート・ウォーターシステムの調整、フィールドの色付けといったような維持・管理するスケジュール、積雪時にインチ毎の除雪コストの予測などのために、MicroWeatherの状況を事前に知ることが必要です。
              </p>
              <p>
                スタジアムのフィールド運営は、MicroWeatherの降水量データを利用してフィールドシステムと比較します。これによりフィールドスタッフは、年間を通じて練習場の草を成長させ、雨のときのはフィールドを守ることができます。
              </p>
              <br />
              <p className="fw-800">フットボールゲーム</p>
              <p>
                ニューイングランド・ペイトリオッツは、ゲームに伴う正確な天候を把握する必要があります。これはゲーム数日前の練習内容選択や、トレーニングに何の器具を利用するかに影響します。例えば、寒くなって、湿ると予想される時、プレイヤーは悪天候下でけん引に役立つ違ったクリートを利用することができます。
              </p>
              <p>
                練習やゲームにtomorrow.ioのMicroWeather予報を導入することで、コーチはいかなるゲームの天候下においても、選手のコンディションをベストな状態にすることができます。
              </p>
              <br />
              <p className="fw-800">飲食物</p>
              <p>
                スタジアムのオペレーション担当は、tomorrow.ioの過去のデータを利用して、天候状況を考慮した飲食物の販売高をモデル化しました。実感温度と飲食物の需要の関連性を研究することにより、チームは潜在的な販売量を予想し、他の天候状況下で人々が通常購入する量より多く販売できるよう、十分な在庫を確保することができるようになりました。
              </p>
              <br />
              <p className="fw-800">雷</p>
              <p>
                NFLフットボールゲームに落雷の危険性がある場合、ジレット・スタジアムのオペレーションチームは、スタジアム周りに10マイルリングを設定し、ゲームに影響する可能性のある天候についてゲームオフィシャルに通知します。
              </p>
              <p className="font-italic">
                もし雷が土地に落ちる可能性がある場合、皆を避難場所へ誘導するためにも、その危険性を事前に把握する必要があります。tomorrow.ioのツールはいつそのような状況が発生するのかと、過ぎ去るのかを教えてくれます。<br/>

                ジェイソン・ストーン氏、Senior Director of Operations、Kraft Sports and Entertainment
              </p>
              <p>
                アメリカンフットボールやサッカーは荒れ模様の天気でもプレイすることができますが、コンサートやモトクロスのようなイベントの場合は、豪雨のせいで遅れる可能性があります。強い風や雷、雪であれば、安全上の理由から遂行することができません。
              </p>
              <br />
              <p className="fw-800">チケット制の観客体験</p>
              <p>
                アメリカンフットボールやサッカーは荒れ模様の天気でもプレイすることができますが、コンサートやモトクロスのようなイベントの場合は、豪雨のせいで遅れる可能性があります。強い風や雷、雪であれば、安全上の理由から遂行することができません。
              </p>
              <p>
              tomorrow.ioのPlatformは、オペレーションチームに厳しい天候についてアラートを送信し、イベントの開催時間を早めたり、遅らせたりする意思決定をするためのリードタイムを最大化します。仮にイベントが延期となった場合は、チケットを購入した観客がソーシャルメディアや他の方法で、そのお知らせを確実に受信するためにも、リードタイムは大変重要です。さらに、正確な気象予報は、不要な中止や遅延を減らし、ファン体験を向上させます。
              </p>
              <div className="pt-5">
                <a className="btn btn-lg btn-primary" href="https://www.tomorrow.io/free-trial?bid=2">
                  弊社の気象専門家にビジネスについて相談
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section">
        <RelatedStories id="gillette-stadium-and-the-new-england-patriots" />
      </section>
    </Layout>
);
